import React from 'react';
// import {useState, useEffect} from 'react';
// import axios from 'axios';
// import AddPost from './components/AddPost';
// import Post from './components/Post';
import "./App.css";
import Header from './components/Header';
import Main from './components/MainPost';
import Footer from './components/Footer';

/*
  AXIOS docs
  https://axios-http.com/docs/intro
 */

function App() {
   
  return (
    <main>
      <Header />
      <Main />
      <Footer />
   </main>
  )
}

export default App;

// ReactDOM.createRoot(document.getElementById('root')).render(<App />); 
import styles from "./Header.module.css";

const header =() =>{
return (
    <header>
      <div className={styles.wrapper}>
        <img className={styles.icon} src="/assets/JohnsPicAwc.png" alt="profile" />
        {/* <h1 className={styles.logo}>Portfolio</h1> */}
      </div>
      </header>
)
}
export default header;


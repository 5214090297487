const Post = (props) => {
  console.log("entered "+ props.id);
    return (
        <div className="post-card">
            <h3 className="post-date">{props.date}</h3>
            <h3 className="post-author">{props.author}</h3>
            <h2 className="post-title">{props.title}</h2>
            <p className="post-body">{props.body}</p>
            <button 
                className="btn-delete" 
                onClick={() => props.deletePost(props.id)}
            >Delete</button>
        </div>
    )   
}

export default Post;
import {useState} from 'react'
import React from 'react';
import swal from 'sweetalert';

function AddPost(props) {
    const [date, setDate] = useState('');
    const [author, setAuthor] = useState('');
    const [title, setTitle] = useState('');
    const [body, setBody] = useState([]);
    const [nmbr, setNmbr] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
    if ( (date.length >  1)  &&  (author.length >  1) && 
         (title.length >  1) &&  (body.length >  1))  
      {   console.log("props " + date+" "+author+" "+title+" "+body);
          let response = props.addPost(date, author, title, body, nmbr); 
          window.nmbr = nmbr;
          console.log("after props "+ JSON.stringify(response));
          setDate('');
          setAuthor('');
          setTitle('');
          setBody('');          
          setNmbr('');          
      } else {
        swal ({
          title: "All Fields Must Have a Value",
        });
      }
    };    
    
    return (
        <form onSubmit={handleSubmit}>
            <h2>Add New Post</h2>
            <div className="input-container">
                <label htmlFor="date">Date</label>
                <input 
                    id="date" 
                    type="date" 
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                />
            </div>
            <div className="input-container">
                <label htmlFor="author">Author</label>
                <input 
                    id="author" 
                    type="text" 
                    placeholder='Name Last, First'
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                />
            </div>
            <div className="input-container">
                <label htmlFor="title">Title</label>
                <input 
                    id="title" 
                    type="text" 
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </div>
            <div className="input-container">
                <label htmlFor="body">Body</label>
                <textarea 
                    id="body" 
                    name="body[]"
                    value={body} 
                    onChange={(e) => setBody(e.target.value)}>
                </textarea>
            </div>
            <div id="mypost" className="input-container">
                <label htmlFor="nmbr"># Posts</label>
                <input 
                    id="nmbr" 
                    type="text" 
                    value={nmbr}
                    placeholder='Display Posts'
                    onChange={(e) => setNmbr(e.target.value)}
                />
              </div>
            <button type="submit" className="btn-submit">Add Post</button>
        </form>
    )
}

export default AddPost;
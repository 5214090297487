import styles from "./Footer.module.css";

export const Footer = () => {
  return (
    <footer className={styles.wrapper}>
      <h5 className={styles.title}>Copyright &copy; 2023 John Sereno</h5>
      {/* <br/>5449 W. 152nd Terrace, <br/>Leawood Kansas, 66224<br/>
      (913) 530-4925 | johnsereno@yahoo.com</h5> */}
    </footer>
  );
};
export default  Footer;
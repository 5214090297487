import React from 'react';
import {useState, useEffect} from 'react';
import axios, { AxiosHeaders } from 'axios';
import AddPost from './AddPost';
import Post from './Post';
import "./MainPost.css";

/*
  AXIOS docs
  https://axios-http.com/docs/intro
 */

function Main() {
  const [posts, setPosts] = useState([]);

  const client = axios.create({
    baseURL: "https://slpost.amgsereno.com/posts/"
  })
  
  const options = {
    headers:{
      'Content-Type':  'application/json;charset=utf-8',
    }
  };

  const fetchPosts = async() => {
    try { 
      const response = await client.get('/listall/'+window.nmbr,options);
      // console.log("plane response "+ JSON.stringify(response))
      // console.log("data response "+ JSON.stringify(response.data))
      setPosts(response.data);
    } catch (error) {
      console.log("THEN ERROR ==>> " +JSON.stringify(error));
    }
  }
 
 useEffect(() => { fetchPosts() }, []);
   
  const addPost = async(date, author, title, body) => {
    const response = await client.post('/addpost', {date,author,title,body}, {
      headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then (function (response) {
      console.log("then response " + JSON.stringify(response.data))
    })
    .catch(function(error) {
      // console.log("THEN ERROR ==>> " +JSON.stringify(error.response.data));
    });    
    fetchPosts();
  };
   
  const deletePost = async(post_id) => {
    const response  = await client.delete(`/deletepost/${post_id}`);
    setPosts(posts.filter((posts1) => posts1.post_id !== post_id));
    fetchPosts();
  };
   
  return (
    <main>
    <h1 style={{color:"green", fontFamily:"script"}}>John's API for Posts</h1>
      <AddPost addPost={addPost}/>
      <section className="posts-container">
      <h2>Posts</h2>
        {posts.map((post) => 
          <Post 
            key={post.post_id} 
            id={post.post_id}
            date={post.date} 
            author={post.author} 
            title={post.title} 
            body={post.body} 
            deletePost={deletePost}
          />
        )}
      </section>
   </main>
  )
}

export default Main;

// ReactDOM.createRoot(document.getElementById('root')).render(<App />); 